import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Link from "gatsby-link";

const HowWorks = () => {
  function Sports() {
    return (
      <StaticImage
        src="../../images/sports.png"
        alt="Wzrost energii i wytrzymałości - napój energetyzujący - shroom"
        title="Wzrost energii i wytrzymałości"
        placeholder="blurred"
        layout="constrained"
        height="100"
        width="100"
        formats={["auto", "webp"]}
      />
    );
  }

  function Brain() {
    return (
      <StaticImage
        src="../../images/brain.png"
        alt="Zwiększenie produktywności - napój energetyzujący - shroom"
        title="Zwiększenie produktywności"
        placeholder="blurred"
        layout="constrained"
        height="100"
        width="100"
        formats={["auto", "webp"]}
      />
    );
  }

  function Guts() {
    return (
      <StaticImage
        src="../../images/guts.png"
        alt="Dobre dla Twojego brzucha - napój energetyzujący - shroom"
        title="Dobre dla Twojego brzucha"
        placeholder="blurred"
        layout="constrained"
        height="100"
        width="100"
        formats={["auto", "webp"]}
      />
    );
  }

  function Head() {
    return (
      <StaticImage
        src="../../images/head.png"
        alt="Świetne samopoczucie - napój energetyzujący - shroom"
        title="Świetne samopoczucie"
        placeholder="blurred"
        layout="constrained"
        height="100"
        width="100"
        formats={["auto", "webp"]}
      />
    );
  }
  function w1() {
    return (
      <StaticImage
        src="../../images/w1.jpg"
        alt="Lepsza regeneracja - napój energetyzujący - shroom"
        title="Lepsza regeneracja"
        placeholder="blurred"
        className="box-s"
        layout="constrained"
        loading="lazy"
        width="505"
        height="529"
        formats={["auto", "webp"]}
      />
    );
  }
  function w2() {
    return (
      <StaticImage
        src="../../images/w2.jpg"
        alt="Poprawa koncentracji i pamięci - napój energetyzujący - shroom"
        title="Poprawa koncentracji i pamięci"
        placeholder="blurred"
        className="box-s"
        layout="constrained"
        loading="lazy"
        width="505"
        height="529"
        formats={["auto", "webp"]}
      />
    );
  }
  function w3() {
    return (
      <StaticImage
        src="../../images/w3.jpg"
        alt="Wsparcie odporności - napój energetyzujący - shroom"
        title="Wsparcie odporności"
        placeholder="blurred"
        className="box-s"
        layout="constrained"
        loading="lazy"
        width="505"
        height="529"
        formats={["auto", "webp"]}
      />
    );
  }
  function w4() {
    return (
      <StaticImage
        src="../../images/w4.jpg"
        alt="Redukcja stresu - napój energetyzujący - shroom"
        title="Redukcja stresu"
        placeholder="blurred"
        className="box-s"
        layout="constrained"
        loading="lazy"
        width="505"
        height="529"
        formats={["auto", "webp"]}
      />
    );
  }
  return (
    <>
      <section id="how-works" style={{ marginTop: "5em" }}>
        <h2
          className="display-8 spec-h"
          style={{ padding: "10px", textAlign: "center" }}
        >
          Odkryj działanie shroom
        </h2>
        <div
          className="row"
          style={{ minWidth: "100%", padding: "2rem", margin: "0" }}
        >
          <div className="col-3" style={{ textAlign: "center" }}>
            {Sports()}
            <h3
              className="my-2"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Wzrost energii
              <br /> i wytrzymałości
            </h3>
            <br />
            {w1()}
            <p className="my-3" style={{ textAlign: "left" }}>
              Bez nagłych skoków energii <br />
              Bez uczucia rozstrzęsienia
              <br />
              Lepsza regeneracja
            </p>
          </div>

          <div className="col-3" style={{ textAlign: "center" }}>
            {Brain()}
            <h3
              className="my-2"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Zwiększenie <br />
              produktywności
            </h3>
            <br />
            {w2()}
            <p className="my-3" style={{ textAlign: "left" }}>
              Poprawa koncentracji i pamięci
              <br />
              <br />
              Wsparcie dla mózgu
            </p>
          </div>

          <div className="col-3" style={{ textAlign: "center" }}>
            {Guts()}
            <h3
              className="my-2"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Dobre dla
              <br />
              Twojego brzucha
            </h3>
            <br />
            {w3()}
            <p className="my-3" style={{ textAlign: "left" }}>
              Zawiera probiotyki <br />
              <br />
              Wsparcie odporności
            </p>
          </div>

          <div className="col-3" style={{ textAlign: "center" }}>
            {Head()}
            <h3
              className="my-2"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Świetne
              <br /> samopoczucie
            </h3>
            <br />
            {w4()}
            <p className="my-3" style={{ textAlign: "left" }}>
              Redukcja stresu <br />
              <br />
              Poprawa ogólnego samopoczucia
            </p>
          </div>
        </div>
        <p id="buttons" style={{ textAlign: "center" }}>
          Wybierz swój <span className="spec-h">shroom</span>
          <br />
          <br />
          <Link
            to="/pl/product/shroom-power-6-pack/"
            className="btn btn-outline-dark mush"
            style={{ margin: "10px" }}
          >
            <span className="spec-h">shroom power</span>&nbsp;
            <span role="img" aria-label="shroom">
              ✨
            </span>
          </Link>
          <Link
            to="/pl/product/shroom-relax-x-6-pack/"
            className="btn btn-outline-dark mush"
            style={{ margin: "10px" }}
          >
            <span className="spec-h">shroom relax</span>&nbsp;
            <span role="img" aria-label="shroom">
              ✨
            </span>
          </Link>
        </p>
      </section>
    </>
  );
};

export default HowWorks;
